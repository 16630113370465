import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import facepalm from '../../assets/images/facepalm.png';

const Styles = styled.div`

	.error-page {
        margin-top: 3.125rem;
        padding: 0 1.25rem;
    }

    .container {
        display: inline-block;
	}

	h1 {
		font-size: 1.75rem;
    	line-height: 3rem;
	}

	.sub {
		font-size: .875rem;
		font-weight: 700;
		margin-top: 1.25rem;
		
		&.margin {
			margin-bottom: 1.875rem;
		}
	}

	ul {
		list-style: none;
		margin: 1.25rem 0;
		
		a {
			font-size: .875rem;
			line-height: 1.6;
			margin-bottom: .625rem;
			display: inline-block;
			color: var(--black);
		}
	}

	.button {
		display: inline-block;
		font-size: .875rem;
		color: var(--white);
    	background: var(--black);
		height: 2.813rem;
		width: 14.375rem;
		text-align: center;
		box-sizing: border-box;
		line-height: 2.813rem;
		display: block;
		border-radius: .188rem;
	}

	.facepalm {
		width: 12.5rem;
    	height: 12.5rem;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${facepalm});
	}

	.err-head {
		max-width: 18.75rem;
    	display: flex;
	}

	@media only screen and (min-width: 1024px) {

		.error-page {
			padding: 0;
		}

		.err-head {
			max-width: 54.5rem;
		}

		.container {
			display: inline-block;
			margin-left: 50%;
			transform: translate(-50%);
		}

		h1 {
			font-size: 4rem;
    		line-height: 4.75rem;
		}

		.facepalm {
			width: 40rem;
		}
	}
	
`;

export default function ErrorPage({data}) {

	return (
		<Styles>
			<div className="pad">
				<section className="error-page">
					<div className="container">
						<div className="col-1 hidden-m"></div>
						<div className="col-10">
							<div className="err-head">
								<h1>Sorry, something went wrong.</h1>
								<div className="facepalm"></div>
							</div>
							<p className="sub">404 - It's us, not you.</p>
							<p className="sub margin">Please check the address again or use one of these handy links:</p>
							<ul>
								<li><Link to="/">Home</Link></li>
								<li><Link to="/blog">Inspiration</Link></li>
							</ul>
							<Link to="/" className="button">Go to Homepage</Link>
						</div>
					</div>
				</section>
			</div>
		</Styles>
	);
}


export const query = graphql`
	query {
		settings: sanitySiteSettings(_id: {eq: "ROMCsiteSettings"})  {
			description
			id
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
				fixed(height: 400, width: 400) {
					base64
					srcWebp
					srcSetWebp
				}
				}
			}
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
		}
		regions: allSanityRomCregionPage {
			nodes {
				slug {
					current
				}
				name
				showInNav
			}
		}
		themes: allSanityRomCtheme {
			nodes {
				name
				slug {
					current
				}
				showInNav
			}
		}
	}
`